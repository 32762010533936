
import RealStandings from "./Component/RealStandings";
import "./Home.css"

function Home() {
    return (
        <>
            <RealStandings />
        </>
    );
}

export default Home;
