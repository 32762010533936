import {useEffect, useState} from 'react';
import {NBA_TEAM} from "../../../Utils/Team";
import Login from "../../Login/Login";
import LogOut from "./LogOut";

function RealStandings() {
    const todayDate = new Date().toISOString().split('T')[0];
    const [standing, setStanding] = useState(null);
    const [userStanding, setUserStanding] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [isEast, setIsEast] = useState(false);

    useEffect(() => {
        const realStanding = async () => {
            // const url = 'https://localhost:8000/api/real_standing';
            const url = 'https://api.pro-nos.fr/api/real_standing';
            const body = {created_at: selectedDate};

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                });

                const data = await response.json();
                setStanding(data);
            } catch (error) {
                setStanding("");
            }
        };

        const showUserStanding = async () => {
            // const url = 'https://localhost:8000/api/user_standing';
            const url = 'https://api.pro-nos.fr/api/user_standing';
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error(`Erreur ${response.status}:`, errorText);
                    return;
                }

                const data = await response.json();
                setUser(data.standing.user)
                if(data.standing.est && data.standing.west){
                    setUserStanding(data.standing);
                }
            } catch (error) {
                setStanding("");
            }
        };

        realStanding();
        showUserStanding();
    }, [selectedDate]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const ChangeConference = (conference) => {
        const east = document.querySelectorAll(".east");
        const west = document.querySelectorAll(".west");
        const isWest = conference === "west";

        east.forEach((team) => (team.hidden = isWest));
        west.forEach((team) => (team.hidden = !isWest));

        setIsEast(conference === "east");
    };

    const renderObject = (obj) => {
        return Object.entries(obj).map(([key, value]) => (
            <div
                key={key}
                className={`team-item p-3 ${key > 10 ? '' : key > 6 ? (isEast ? 'bg-blue-300' : 'bg-red-300') : (isEast ? 'bg-blue-500' : 'bg-red-500')}`}
            >
                <span className="flex justify-between">
                    <span>{key}. {value.team}</span>
                    <span>{value.win} - {value.loss}</span>
                </span>
            </div>
        ));
    };

    const redirectUserStanding = () => {
        // window.location.href = "http://localhost:3000/user_standing";
        window.location.href = "http://pro-nos/user_standing";
    }

    return (
        <div className="flex flex-col items-center p-8 space-y-8 min-h-screen">
            {/* Barre de recherche et date */}
            <div className="flex items-center justify-between w-full max-w-6xl">
                {/*<input*/}
                {/*    type="text"*/}
                {/*    placeholder="Recherche"*/}
                {/*    className="p-2 border border-gray-300 rounded-lg focus:outline-none"*/}
                {/*/>*/}
                <input
                    type="date"
                    min="2024-03-18"
                    max={todayDate}
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="p-2 ml-4 border rounded-lg"
                />

                <LogOut user={user}/>
            </div>

            <div className="flex space-x-8">
                <button onClick={() => ChangeConference("east")} className={`text-lg ${isEast ? 'font-bold' : ''}`}>
                    Classement de l'est
                </button>
                <button onClick={() => ChangeConference("west")} className={`text-lg ${!isEast ? 'font-bold' : ''}`}>
                    Classement de l'ouest
                </button>
            </div>

            <div className="flex justify-center space-x-8 w-full max-w-6xl">
                <div className="w-1/2">
                        <h2 className="text-center mb-2">Classement NBA</h2>
                        <div className="rounded-lg shadow-lg">
                        <div className="space-y-1">
                            <div className="west">{standing ? renderObject(standing.west) :
                                <div>Aucun classement</div>}</div>
                            <div className="east" hidden>{standing ? renderObject(standing.est) :
                                <div>Aucun classement</div>}</div>
                        </div>
                    </div>
                </div>


                <div className="w-1/2 login-logged">
                    <h2 className="text-center mb-2">Ton pro no</h2>
                    <div className="rounded-lg shadow-lg">
                        <div className="space-y-1">
                            <div className="west">{user ? userStanding ? renderObject(userStanding.west) :
                                <div>
                                    <span className="p-2"> Pas de classement enregistré </span>
                                    <div className="underline font-bold p-2 cursor-pointer" onClick={redirectUserStanding}>Fais ton propre classement</div>
                                </div> : <Login/>}</div>
                            <div className="east" hidden>{user ? userStanding ? renderObject(userStanding.est) :
                                <div>
                                    <span className="p-2"> Pas de classement enregistré </span>
                                    <div className="underline font-bold p-2 cursor-pointer" onClick={redirectUserStanding}>Fais ton propre classement</div>
                                </div> : <Login/>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default RealStandings;
