function LogOut(props){

    const handleSubmit = async () => {
        const url = 'https://localhost:8000/api/logout';

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.ok) {
                window.location.reload();
            } else {
                console.error('Failed to logout');
            }
        } catch (error) {
            console.error('Erreur lors de la déconnexion:', error);
        }
    }
    return props.user ? (
        <div onClick={handleSubmit} className="cursor-pointer">Déconnexion</div>
    ) : null;
}

export default LogOut