const WEST_TEAM = {
    "Dallas Mavericks" : {
        "id" : 1,
        "primary_color" : "#00538C",
        "secondary_color" : "#002B5e",
        "key" : "DAL"
    },
    "Denver Nuggets": {
        "id" : 2,
        "primary_color" : "#0E2240",
        "secondary_color" : "#FEC524",
        "key" : "DEN"
    },
    "Golden State Warriors" : {
        "id" : 3,
        "primary_color" : "#1D428A",
        "secondary_color" : "#ffc72c",
        "key" : "GS"
    },
    "Houston Rockets" : {
        "id" : 4,
        "primary_color" : "#CE1141",
        "secondary_color" : "#000000",
        "key" : "HOU"
    },
    "Los Angeles Clippers": {
        "id" : 6,
        "primary_color" : "#c8102E",
        "secondary_color" : "#1d428a",
        "key" : "LAC"
    },
    "Los Angeles Lakers": {
        "id" : 6,
        "primary_color" : "#552583",
        "secondary_color" : "#FDB927",
        "key" : "LAL"
    },
    "Memphis Grizzlies": {
        "id" : 7,
        "primary_color" : "#5D76A9",
        "secondary_color" : "#12173F",
        "key" : "MEN"
    },
    "Minnesota Timberwolves": {
        "id" : 8,
        "primary_color" : "#0C2340",
        "secondary_color" : "#236192",
        "key" : "MIN"
    },
    "New Orleans Pelicans": {
        "id" : 9,
        "primary_color" : "#0C2340",
        "secondary_color" : "#C8102E",
        "key" : "NO"
    },
    "Oklahoma City Thunder": {
        "id" : 10,
        "primary_color" : "#007ac1",
        "secondary_color" : "#ef3b24",
        "key" : "OKC"
    },
    "Phoenix Suns": {
        "id" : 11,
        "primary_color" : "#1d1160",
        "secondary_color" : "#e56020",
        "key" : "PHO"
    },
    "Portland Trail Blazers": {
        "id" : 12,
        "primary_color" : "#E03A3E",
        "secondary_color" : "#000000",
        "key" : "POR"
    },
    "Sacramento Kings": {
        "id" : 13,
        "primary_color" : "#5a2d81",
        "secondary_color" : "#63727A",
        "key" : "SAC"
    },
    "San Antonio Spurs": {
        "id" : 14,
        "primary_color" : "#c4ced4",
        "secondary_color" : "#000000",
        "key" : "SA"
    },
    "Utah Jazz": {
        "id" : 15,
        "primary_color" : "#002B5C",
        "secondary_color" : "#00471B",
        "key" : "UTA"
    }
}

export { WEST_TEAM }