import {useEffect, useRef, useState} from "react";
import {EAST_TEAM} from "../../Utils/East";
import {WEST_TEAM} from "../../Utils/West";
import "../UserStanding/UserStanding.css"

const UserStanding = () => {
    const [eastTeams, setEastTeams] = useState(EAST_TEAM);
    const [westTeams, setWestTeams] = useState(WEST_TEAM);
    const ref = useRef({});
    const createInitialData = (test) => {
        const initialData = {};
        for (let i = 1; i <= 15; i++) {
            initialData[i] = {team: test, win: 82, loss: 0};
        }
        return initialData;
    };

    const [formData, setFormData] = useState({
        east: createInitialData("Chicago Bulls"),
        west: createInitialData("Dallas Mavericks"),
    });

    useEffect(() => {

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const url = 'https://localhost:3000/api/user_standing/add';
        const url = 'https://api.pro-nos.fr/api/user_standing/add';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });
            console.log('classement enregistrer', response);
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la requête:', error);
        }

    };

    const handleChange = (conference, e, team = false) => {
        const {name, value, id} = e;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [conference]: {
                ...prevFormData[conference],
                [name]: {
                    ...prevFormData[conference][name],
                    [id]: value,
                },
            },
        }));
        console.log(formData);

        if (team) {
            selectTeamChange(e, conference)
        }
    };

    const selectTeamChange = (e, conf) => {
        let reference = e.name + "-" + conf

        if (conf === "east") {
            EAST_TEAM[e.value]["use"] = !EAST_TEAM[e.value]["use"]
            if (ref[reference] !== undefined) EAST_TEAM[ref[reference]]["use"] = false
        } else {
            WEST_TEAM[e.value]["use"] = !WEST_TEAM[e.value]["use"]
            if (ref[reference] !== undefined) WEST_TEAM[ref[reference]]["use"] = false
        }
        ref[reference] = e.value
    }

    const renderOptions = (conference) => {
        const teams = conference === "east" ? eastTeams : westTeams;
        return Object.entries(teams).map(([key, value]) => (
            <option
                key={value.id}
                value={key}
                id={value.id.toString()}
                class={value.use === true ? "use" : "not-use"}
                disabled={value.use}
            >
                {key}
            </option>
        ));
    };

    const renderObject = (obj, conference) => {
        return Object.entries(obj).map(([key, value], index) => (
            <div
                key={`${conference}-${index + 1}`}
                className={`team-item pt-2 px-2 ${index + 1 > 10 ? '' : index + 1 > 6 ? (conference === "east" ? 'bg-blue-300' : 'bg-red-300') : (conference === "east" ? 'bg-blue-500' : 'bg-red-500')}`}
            >
            <span className="flex justify-between items-center">
                <span>{`${index + 1}`}</span>
                <select
                    id="team"
                    name={(index + 1).toString()}
                    onChange={(e) => handleChange(conference, e.target, true)}
                    className="p-2 border rounded-md"
                >
                    {renderOptions(conference)}
                </select>

                <input
                    type="number"
                    id="win"
                    name={(index + 1).toString()}
                    min="0"
                    max="82"
                    onChange={(e) => handleChange(conference, e.target)}
                    className="p-2 border rounded-md mx-2"
                    placeholder="Victoires"
                />

                <input
                    type="number"
                    id="loss"
                    name={(index + 1).toString()}
                    min="0"
                    max="82"
                    onChange={(e) => handleChange(conference, e.target)}
                    className="p-2 border rounded-md"
                    placeholder="Défaites"
                />
            </span>
            </div>
        ));
    };

    return (
        <div className="flex flex-col items-center p-8 min-h-screen">
            <form onSubmit={handleSubmit} className="w-full max-w-6xl">
                <div className="flex space-x-8">
                    {/* Conférence Est */}
                    <div className="w-1/2">
                        <h2 className="text-center mb-2">Ton Classement Ouest</h2>
                        <div className="rounded-lg shadow-lg bg-white">
                            <div className="east">
                                {renderObject(westTeams, "west")}
                            </div>
                        </div>
                    </div>

                    {/* Conférence Ouest */}
                    <div className="w-1/2">
                        <h2 className="text-center mb-2">Ton Classement Est</h2>
                        <div className="rounded-lg shadow-lg bg-white">
                            <div className="west">
                                {renderObject(eastTeams, "east")}
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="bg-white my-4 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                >
                    Enregistrer
                </button>
            </form>
        </div>
    );
};

export default UserStanding;