const EAST_TEAM = {
    "Atlanta Hawks": {
        "id" : 1,
        "primary_color" : "#C8102E",
        "secondary_color" : "#FDB927",
        "key" : "ATL"
    },
    "Boston Celtics": {
        "id": 2,
        "primary_color" : "#007A33",
        "secondary_color" : "#FDB927",
        "key" : "BOS"
    },
    "Brooklyn Nets": {
        "id" : 3,
        "primary_color" : "#000000",
        "secondary_color" : "#FFFFFF",
        "key" : "BRO"
    },
    "Charlotte Hornets": {
        "id" : 4,
        "primary_color" : "#1d1160",
        "secondary_color" : "#00788C",
        "key" : "CHA"
    },
    "Chicago Bulls": {
        "id" : 5,
        "primary_color" : "#CE1141",
        "secondary_color" : "#000000",
        "key" : "CHI"
    },
    "Cleveland Cavaliers": {
        "id" : 6,
        "primary_color" : "#860038",
        "secondary_color" : "#041E42",
        "key" : "CLE"
    },
    "Detroit Pistons": {
        "id" : 7,
        "primary_color" : "#C8102E",
        "secondary_color" : "#1d42ba",
        "key" : "DET"
    },
    "Indiana Pacers": {
        "id" : 8,
        "primary_color" : "#002D62",
        "secondary_color" : "#FDBB30",
        "key" : "IND"
    },
    "Miami Heat": {
        "id" : 9,
        "primary_color" : "#98002E",
        "secondary_color" : "#F9A01B",
        "key" : "MIA"
    },
    "Milwaukee Bucks": {
        "id" : 10,
        "primary_color" : "#00471B",
        "secondary_color" : "#EEE1C6",
        "key" : "MIL"
    },
    "New York Knicks": {
        "id" : 11,
        "primary_color" : "#006BB6",
        "secondary_color" : "#F58426",
        "key" : "NY"
    },
    "Orlando Magic": {
        "id" : 12,
        "primary_color" : "#0077c0",
        "secondary_color" : "#C4ced4",
        "key" : "ORL"
    },
    "Philadelphia 76ers": {
        "id" : 13,
        "primary_color" : "#ed174c",
        "secondary_color" : "#C4ced4",
        "key" : "PHI"
    },
    "Toronto Raptors": {
        "id" : 14,
        "primary_color" : "#ce1141",
        "secondary_color" : "#000000",
        "key" : "TOR"
    },
    "Washington Wizards": {
        "id" : 15,
        "primary_color" : "#002B5C",
        "secondary_color" : "#e31837",
        "key" : "WAS"
    }
}

export { EAST_TEAM }