import { Routes, Route } from 'react-router-dom';
import Home from "./Views/Home/Home";
import Signup from "./Views/Signup/Signup";
import Login from "./Views/Login/Login";
import UserStanding from "./Views/UserStanding/UserStanding";
import './App.css'

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/user_standing" element={<UserStanding />} />
            </Routes>
        </>
    );
};

export default App;