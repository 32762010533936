import logo from '../../logo.svg';
import './Login.css';
import {useState} from "react";
import {parseJwt, setJwtDecoded} from "../../Utils/Jwt";
import Signup from "../Signup/Signup";

function Login() {
    const [formData, setFormData] = useState({
        mail: '',
        password: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const signupChange = () => {
        document.querySelector(".signup").hidden = false
        document.querySelector(".login").hidden = true
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        // const url = 'https://localhost:8000/api/login';
        const url = 'https://api.pro-nos.fr/api/login';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });
            if(response.ok){
                window.location.reload();
            }else{
                console.log("login failed")
            }


        } catch (error) {
            console.error('Erreur lors de l\'envoi de la requête:', error);
        }
    };
    return (
        <div className="App">
            <div className="login">
                <form className="flex flex-col p-4" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2">Adresse mail</label>
                        <input
                            id="mail"
                            name="mail"
                            type="text"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="date-input" className="block text-lg font-medium mb-2">Mot de passe</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div onClick={signupChange} className="cursor-pointer py-2 underline">S'inscrire</div>
                    <button type="submit"
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                        Se connecter
                    </button>
                </form>

            </div>
            <div className="w-1/2 signup" hidden>
                <Signup />
            </div>

        </div>
    );
}

export default Login;
