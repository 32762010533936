import './Signup.css';
import {useState} from "react";

function Signup() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password:'',
        confirm_password:''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]:value
        });
    }

    const signupChange = () => {
        document.querySelector(".signup").hidden = true
        document.querySelector(".login").hidden = false
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const url = 'https://localhost:8000/api/signup';
        const url = 'https://api.pro-nos.fr/api/signup';

        if (formData.password === formData.confirm_password) {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                const data = await response.json(); // Parse the JSON response
                console.log('Réponse:', data);
            } catch (error) {
                console.error('Erreur lors de l\'envoi de la requête:', error);
            }


        }
    }
    return (
        <div className="App">
            <div className="signup">
                <form className="flex flex-col p-4" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-lg font-medium mb-2">Identifiant</label>
                        <input
                            id="username"
                            name="username"
                            type="text"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="date-input" className="block text-lg font-medium mb-2">Adresse mail</label>
                        <input
                            id="mail"
                            name="mail"
                            type="email"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="date-input" className="block text-lg font-medium mb-2">Mot de passe</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="date-input" className="block text-lg font-medium mb-2">Confirmer le mot de passe</label>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            className="p-2 border rounded"
                            required={true}
                            onChange={handleChange}
                        />
                        <div onClick={signupChange} className="cursor-pointer py-2 underline">Se connecter</div>
                        <button type="submit" className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                            S'inscrire
                        </button>

                    </div>
                </form>
            </div>

            <div className="w-1/2 login" hidden>
                {/*<Login />*/}
            </div>
        </div>
    );
}

export default Signup;
